<ngb-toast
  *ngFor="let toast of _toast.toasts"
  [header]="toast.header"
  [autohide]="true"
  [delay]="toast.delay || 4000"
  class="bg-danger text-light"
  (hidden)="_toast.remove(toast)"
>
  {{ toast.body }}
</ngb-toast>
