import { Component } from '@angular/core';

class Business {
  constructor(public phone: string[], public address: string, public email: string, public linkToMaps: string) {}

  getFormattedPhone() {
    let a = this.phone[0].match(/.{1,3}/g);
    return a ? a.join(' ') : '';
  }
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  business = new Business(
    ['948939248', '(01) 4801702'],
    'Jr. 28 de julio Mz. D Lt. 13 – Pillco Marca - Huánuco',
    'soporte@infistel.pe',
    'https://www.google.com/maps/place/SERCOR+S.A.C./@-9.959355,-76.249021,1119m/data=!3m1!1e3!4m5!3m4!1s0x0:0xbbe544400a886f9a!8m2!3d-9.959358!4d-76.2490202?hl=es-419',
  );

  getAddress(): string {
    let splitted = this.business.address.split('-');
    splitted.pop();
    return splitted.join('-');
  }
}
