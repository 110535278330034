import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule, CardModule, ModalModule, BreadcrumbModule } from './components';
import { TodoListRemoveDirective } from './components/todo/todo-list-remove.directive';
import { TodoCardCompleteDirective } from './components/todo/todo-card-complete.directive';
import { ClickOutsideModule } from 'ng-click-outside';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { TableComponent } from './components/table/table.component';
import { ToastComponent } from './components/toast/toast.component';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { LoadingResourceComponent } from './loading-resource/loading-resource.component';

/*import 'hammerjs';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';*/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    ModalModule,
    ClickOutsideModule,
    BreadcrumbModule,
    NgbToast,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    ModalModule,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    ClickOutsideModule,
    SpinnerComponent,
    BreadcrumbModule,
    ToastComponent,
    LoadingResourceComponent,
  ],
  declarations: [
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    SpinnerComponent,
    TableComponent,
    ToastComponent,
    LoadingResourceComponent,
  ],
})
export class SharedModule {}
