import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ClientReceipt } from 'src/app/models/api-response';
import { ClientReceiptMarked } from '../receipt.component';

@Component({
  selector: 'app-table-receipt',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() clientReceiptList: ClientReceipt[];
  @Output() onReceiptSelected: EventEmitter<ClientReceiptMarked | undefined> = new EventEmitter<
    ClientReceiptMarked | undefined
  >();

  clientReceiptListMarked: ClientReceiptMarked[] = [];
  pageSize = 10;
  page = 1;
  checked = false;
  mobileView: boolean;

  constructor() {
    this.mobileView = globalThis.innerWidth > 768 ? false : true;
  }

  ngOnInit(): void {
    if (this.clientReceiptList.length) this.refreshReceipts();
  }

  setReceiptSelected(receipt: ClientReceiptMarked) {
    if (!this.checked) {
      this.onReceiptSelected.emit(receipt);
    } else {
      this.onReceiptSelected.emit(undefined);
    }
  }

  refreshReceipts() {
    let receiptToPay = this.clientReceiptList.reverse().find((e) => e.status !== 'PAGADO');
    this.clientReceiptListMarked = this.clientReceiptList.reverse().map((e) => {
      let receiptMarked: ClientReceiptMarked = { ...e, toPay: false };
      if (receiptMarked.id === receiptToPay?.id) receiptMarked.toPay = true;
      return receiptMarked;
    });

    const rec = this.clientReceiptListMarked.find((r) => r.toPay);
    if (rec) {
      this.checked = true;
      this.onReceiptSelected.emit(rec);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(_: Event) {
    if (globalThis.innerWidth > 768) {
      this.mobileView = false;
    } else {
      this.mobileView = true;
    }
  }
}
