import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Culqi } from '../models/culqi';
import { PaymentMethodNames } from '../components/payment-gateway/data';
import {
  CulqiChargeResponse,
  CulqiChargeResponseSchema,
  CulqiExchangeRateResponse,
  CulqiExchangeRateResponseSchema,
} from '../models/api-response/culqi';
import { parseResponse } from './parse-response.operator';
import { UserIdentity } from '../models/api-response/user';
import { ChargeBody } from '../models/api-response/receipt';
import { NGXLogger } from 'ngx-logger';

declare let Culqi: Culqi;

@Injectable({
  providedIn: 'root',
})
export class NgCulqiService {
  private _urlCulqi: string = environment.API_CULQI;
  private _urlApi: string = environment.API_URL;

  constructor(public _http: HttpClient, private _log: NGXLogger) {}

  initCulqi() {
    Culqi.publicKey = environment.PUBLIC_KEY_CULQI;
  }

  payOrder(description: string, amount: number, paymentMethod: PaymentMethodNames, order?: string) {
    let paymentMethods = {
      tarjeta: false,
      bancaMovil: false,
      billetera: false,
      yape: false,
      agente: false, // dejar en false
      cuotealo: false, // dejar en false
    };

    switch (paymentMethod) {
      case 'card':
        paymentMethods.tarjeta = true;
        break;
      case 'wallet':
        paymentMethods.billetera = true;
        break;
      case 'yape':
        paymentMethods.yape = true;
        break;
      case 'banking':
        paymentMethods.bancaMovil = true;
        break;
    }

    Culqi.settings({
      title: 'Infistel SAC',
      currency: 'PEN',
      description,
      amount,
      order,
    });

    Culqi.options({
      lang: 'auto',
      modal: true,
      installments: true,
      customButton: '',
      paymentMethods,
    });

    this.open();
  }

  createOrder(description: string, amount: number, userData: UserIdentity): Observable<any> {
    let body = {
      amount: Number((amount * 100).toFixed(2)),
      currency_code: 'PEN',
      description: description,
      order_number: 'orden-' + this.getRandomInt(1, 9999),
      client_details: {
        first_name: userData.nombres,
        last_name: userData.name.length == 11 ? userData.nombres : userData.apellidos,
        email: userData.email,
        phone_number: userData.celular,
      },
      expiration_date: Math.floor(Date.now() / 1000) + 24 * 60 * 60,
      confirm: false,
    };

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', environment.PRIVATE_KEY_CULQI);

    return this._http.post(this._urlCulqi + 'orders', body, { headers });
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  open() {
    Culqi.open();
  }

  close() {
    Culqi.close();
  }

  show() {
    this._log.info(Culqi);
  }

  charges(json: Partial<ChargeBody>): Observable<CulqiChargeResponse> {
    let body = JSON.stringify(json);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', environment.PRIVATE_KEY_CULQI);

    return this._http
      .post<CulqiChargeResponse>(this._urlCulqi + 'charges', body, { headers })
      .pipe(parseResponse(CulqiChargeResponseSchema));
  }

  getExchangeRate(): Observable<CulqiExchangeRateResponse> {
    return this._http
      .get<CulqiExchangeRateResponse>(this._urlApi + 'exchanges/pe')
      .pipe(parseResponse(CulqiExchangeRateResponseSchema));
  }
}
