<!-- <div class="d-flex align-items-center">
  <p class="card-text m-0 pe-2">Mostrar</p>
  <select
    class="form-select"
    aria-label="Seleccione"
    style="width: auto"
    [(ngModel)]="pageSize"
    (ngModelChange)="refreshReceipts()"
  >
    <option [ngValue]="10">10</option>
    <option [ngValue]="15">15</option>
    <option [ngValue]="20">20</option>
  </select>
  <p class="m-0 ps-2">elementos</p>
</div>
 -->
<div *ngIf="mobileView">
  <div
    class="card my-2"
    *ngFor="let receipt of clientReceiptListMarked"
  >
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <strong>
          Recibo N {{ receipt.id }} -
          <h5 style="display: inline">
            <span
              class="badge"
              [ngClass]="{
                'bg-success': receipt.status === 'PAGADO',
                'bg-danger': receipt.status === 'VENCIDO',
                'bg-info': receipt.status === 'PENDIENTE'
              }"
            >
              {{ receipt.status }}
            </span>
          </h5>
        </strong>
        <div *ngIf="receipt.status !== 'PAGADO'">
          <strong
            *ngIf="receipt.toPay"
            class="me-1"
          >
            a pagar
          </strong>
          <input
            *ngIf="receipt.toPay"
            class="form-check-input"
            type="checkbox"
            (click)="setReceiptSelected(receipt)"
            [(ngModel)]="checked"
            id="flexCheckDefault"
          />
          <input
            type="checkbox"
            *ngIf="!receipt.toPay"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="card-title">
        {{ receipt.monthDescription.toLowerCase() + ' ' + receipt.expirationDate.split('-')[0] }}
      </div>
      <div class="card-text">
        <div>Emisión: {{ receipt.issueDate | date : 'dd/MM/yyyy' : 'GMT' }}</div>
        <div>Vencimiento: {{ receipt.expirationDate | date : 'dd/MM/yyyy' : 'GMT' }}</div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            Monto:
            <h6
              class="m-0 ms-2"
              style="line-height: normal"
            >
              {{ receipt.totalAmount | currency : 'S/. ' }}
            </h6>
          </div>
          <app-download-receipt [receipt]="receipt"></app-download-receipt>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="table-responsive"
  *ngIf="!mobileView"
>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Mes</th>
        <th scope="col">N recibo</th>
        <th scope="col">Emisión</th>
        <th scope="col">Vencimiento</th>
        <th scope="col">Monto</th>
        <th scope="col">Estado</th>
        <th
          scope="col"
          class="text-center"
        >
          Descargar
        </th>
        <th scope="col">Pagar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let receipt of clientReceiptListMarked">
        <td class="text-capitalize">
          {{ receipt.monthDescription.toLowerCase() + ' ' + receipt.expirationDate.split('-')[0] }}
        </td>
        <td>{{ receipt.id }}</td>
        <td>{{ receipt.issueDate | date : 'dd/MM/yyyy' : 'GMT' }}</td>
        <td>{{ receipt.expirationDate | date : 'dd/MM/yyyy' : 'GMT' }}</td>
        <td>{{ receipt.totalAmount | currency : 'S/. ' }}</td>
        <td>
          <p
            class="badge"
            [ngClass]="{
              'bg-success': receipt.status === 'PAGADO',
              'bg-danger': receipt.status === 'VENCIDO',
              'bg-info': receipt.status === 'PENDIENTE'
            }"
          >
            {{ receipt.status }}
          </p>
        </td>
        <td class="text-center">
          <app-download-receipt [receipt]="receipt"></app-download-receipt>
        </td>
        <td *ngIf="receipt.status !== 'PAGADO'">
          <input
            *ngIf="receipt.toPay"
            class="form-check-input"
            type="checkbox"
            (click)="setReceiptSelected(receipt)"
            [(ngModel)]="checked"
            id="flexCheckDefault"
          />
          <input
            type="checkbox"
            *ngIf="!receipt.toPay"
            disabled
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- <div class="d-flex justify-content-between">
  <p>Mostrando 1 al 10 de {{ clientReceiptListMarked.length }} elementos</p>
  <ngb-pagination
    [(page)]="page"
    [pageSize]="pageSize"
    [collectionSize]="clientReceiptListMarked.length"
  ></ngb-pagination>
</div> -->
