import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  roles?: number[];
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'cliente',
    title: 'Cliente',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      /*{
        id: 'inicio-ie',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard-institucion',
        icon: 'feather icon-home',
        breadcrumbs: false,
        roles: [5],
      },*/
      {
        id: 'inicio-dedicado',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard-dedicado',
        icon: 'feather icon-home',
        breadcrumbs: false,
        roles: [7],
      },
      {
        id: 'inicio',
        title: 'Inicio',
        type: 'item',
        url: '/inicio',
        icon: 'feather icon-home',
        breadcrumbs: false,
        roles: [3],
      },
      {
        id: 'perfil',
        title: 'Perfil',
        type: 'item',
        url: '/perfil',
        icon: 'feather icon-user',
        breadcrumbs: false,
      },
      /*{
        id: 'conformidad-ie',
        title: 'Conformidad',
        type: 'item',
        url: '/conformidad-servicio',
        icon: 'feather icon-check-circle',
        breadcrumbs: false,
        roles: [5, 6]
      },*/
      {
        id: 'logs',
        title: 'Notificaciones',
        type: 'item',
        url: '/notificaciones',
        icon: 'feather icon-bell',
        breadcrumbs: false,
        roles: [7]
      },
      {
        id: 'servicios-ie',
        title: 'Servicios',
        type: 'item',
        url: '/servicios-ie',
        icon: 'feather icon-server',
        breadcrumbs: false,
        roles: [5]
      },
      /*{
        id: 'mesa-ayuda',
        title: 'Mesa de Ayuda',
        type: 'item',
        url: '/mesa-ayuda',
        icon: 'feather icon-mail',
        breadcrumbs: false,
        roles: [5, 6]
      },*/
      {
        id: 'recibos-y-pagos',
        title: 'Recibos y Pagos',
        type: 'item',
        url: '/recibos-y-pagos',
        icon: 'feather icon-file-text',
        breadcrumbs: false,
        roles: [3],
      },
      {
        id: 'servicios',
        title: 'Servicios',
        type: 'item',
        url: '/servicios',
        icon: 'feather icon-server',
        breadcrumbs: false,
        roles: [3],
      },
      {
        id: 'contacto',
        title: 'Contacto',
        type: 'item',
        url: '/contacto',
        icon: 'feather icon-info',
        breadcrumbs: false,
      },
    ],
  },
  // links dev
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
