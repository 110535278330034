<div
  class="user-profile user-card"
  *ngIf="isUgel"
></div>
<!-- profile body start -->
<div
  class="row"
  *ngIf="!isUgel"
>
  <div class="col-md-6 order-md-1">
    <app-card [hidHeader]="true">
      <div class="card-title">
        <div class="d-flex justify-content-between">
          <h5>Deuda total</h5>
          <h5>{{ totalDebt | currency : 'S/. ' }}</h5>
        </div>
        <hr />
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <p
          class="card-text text-danger mt-0 mb-0"
          *ngIf="totalDebt > 0"
        >
          Paga a tiempo y evita suspensiones
        </p>
        <strong
          class="card-text text-success mt-0 mb-0"
          *ngIf="totalDebt === 0"
        >
          No tienes deudas pendientes
        </strong>
        <span class="d-flex align-items-center">
          <a
            class="btn btn-primary"
            routerLink="/recibos-y-pagos"
            role="button"
          >
            Ver recibos
          </a>
        </span>
      </div>
    </app-card>
  </div>
  <div class="col-md-6 order-md-1">
    <app-card [hidHeader]="true">
      <div class="d-flex justify-content-between align-items-center">
        <ng-icon
          size="30"
          name="bootstrapChevronLeft"
          role="button"
          (click)="carousel.prev()"
          class="pe-2"
        ></ng-icon>
        <ngb-carousel
          class="flex-fill"
          [showNavigationIndicators]="false"
          [showNavigationArrows]="false"
        >
          <ng-template
            ngbSlide
            *ngFor="let plan of clientPlans"
          >
            <div class="card-title">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="m-0">{{ plan.nombrePlan }}</h5>
                <div class="badge bg-{{ plan.status === 'ACTIVO' ? 'success' : 'danger' }} d-flex align-items-center">
                  <p class="m-0">{{ plan.status }}</p>
                </div>
              </div>
              <hr />
            </div>

            <div class="d-flex justify-content-end">
              <span class="d-flex align-items-center">
                <a
                  class="btn btn-primary"
                  role="button"
                  routerLink="/servicios"
                >
                  Ver servicios
                </a>
              </span>
            </div>
          </ng-template>
        </ngb-carousel>
        <ng-icon
          size="30"
          name="bootstrapChevronRight"
          role="button"
          (click)="carousel.next()"
          class="ps-2"
        ></ng-icon>
      </div>
    </app-card>
  </div>
  <div class="col-md-6 order-md-1">
    <app-card [hidHeader]="true">
      <div class="card-title">
        <div class="d-flex justify-content-center">
          <h5 class="m-0">Historial de recibos</h5>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <apx-chart
          #chart
          style="width: 100%"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [title]="chartOptions.title"
        ></apx-chart>
      </div>
    </app-card>
  </div>
  <div class="col-md-6 order-md-1">
    <!--<app-card [hidHeader]="true">
      <div class="d-flex justify-content-center">
        <img
          src="https://res.cloudinary.com/det8h7rxr/image/upload/v1674598433/test/Screenshot_from_2023-01-24_17-11-59_daif3h.png"
        />
      </div>
    </app-card>-->
  </div>
</div>
