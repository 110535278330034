import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientReceiptEndpoint } from './client-receipt.endpoint';
import { CustomerEndpoint } from './customer.endpoint';
import { PaymentMethodDetailEndpoint } from './payment-method-detail.endpoint';
import { ReceiptPayEndpoint } from './receipt.endpoint';
import { UserGroupEndpoint } from './user-group.endpoint';
import { RouterEndpoint } from './router.endpoint';

/** responsible for all Infistel API calls */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.API_URL + environment.API_VERSION + '/';

  /** All customer related services */
  customer: CustomerEndpoint;
  paymentMethodDetail: PaymentMethodDetailEndpoint;
  receiptPay: ReceiptPayEndpoint;
  customerReceipt: ClientReceiptEndpoint;
  userGroup: UserGroupEndpoint;
  router: RouterEndpoint;

  constructor(private _httpClient: HttpClient) {
    this.customer = new CustomerEndpoint({ _httpClient, path: this.baseUrl + 'client-services/' });
    this.paymentMethodDetail = new PaymentMethodDetailEndpoint({
      _httpClient,
      path: environment.API_URL + 'forma-pago-detalle/',
    });
    this.receiptPay = new ReceiptPayEndpoint({ _httpClient, path: environment.API_URL + 'recibo-cliente-pago/' });
    this.customerReceipt = new ClientReceiptEndpoint({ _httpClient, path: this.baseUrl + 'recibo-cliente/' });
    this.userGroup = new UserGroupEndpoint({ _httpClient, path: this.baseUrl + 'client-services/' });
    this.router = new RouterEndpoint({ _httpClient, path: this.baseUrl + 'client-services/' });
  }
}
