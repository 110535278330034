import { z } from 'zod';
import { BaseApiResponseSchema } from './base-api-response';

const PaymentMethodSchema = z.object({
  id: z.number(),
  nombre: z.string(),
});
const PaymentMethodDetailSchema = z.object({
  comision_cliente_fija: z.string(),
  comision_cliente_porcentaje: z.string(),
  comision_empresa_fija: z.string(),
  comision_empresa_porcentaje: z.string(),
  empresa_id: z.number().nullable(),
  forma_pago: PaymentMethodSchema,
  forma_pago_id: z.number(),
  id: z.number(),
  nombre_empresa: z.string(),
});
export type PaymentMethodDetail = z.infer<typeof PaymentMethodDetailSchema>;
export const PaymentMethodDetailResponseSchema = BaseApiResponseSchema.extend({
  forma_pago_detalle: PaymentMethodDetailSchema,
});
export type PaymentMethodDetailResponse = z.infer<typeof PaymentMethodDetailResponseSchema>;
