import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NavigationItem } from './navigation';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  navigation: any;

  @Output() onNavMobCollapse = new EventEmitter();

  @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
  @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

  constructor(public nav: NavigationItem, private location: Location, private _authService: AuthService,) {
    this.navigation = this.nav.get();
  }

  ngOnInit() {
    this.filterMenuItems();

    if (window.innerWidth < 992) {
      setTimeout(() => {
        document.querySelector('.pcoded-navbar')?.classList.add('menupos-static');
        (document.querySelector('#sidebar-menu') as HTMLElement).style.maxHeight = '100%';
      }, 500);
    }
  }

  navMob() {
    if (
      window.innerWidth < 992 &&
      document.querySelector('app-navigation.pcoded-navbar')?.classList.contains('mob-open')
    ) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    /*if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }*/
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('pcoded-trigger');
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('pcoded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('pcoded-trigger');
        last_parent.classList.add('active');
      }
    }
  }

  filterMenuItems() {
    this.navigation = this.navigation
      .filter((item: any) =>
        item.roles ? this._authService.isUserAllowed(item.roles) : true,
      )
      .map((item: any) => {
        if (item.children) {
          item.children = this.filterSubItems(item.children);
        }

        return item;
      });
  }

  private filterSubItems(subItems: any) {
    const arr: any[] = subItems;

    return arr.filter(sub =>
      sub.roles ? this._authService.isUserAllowed(sub.roles) : true,
    );
  }
}
