import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-resource',
  templateUrl: './loading-resource.component.html',
  styleUrls: ['./loading-resource.component.scss'],
})
export class LoadingResourceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
