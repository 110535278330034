<div class="navbar-wrapper">
  <div
    (clickOutside)="navMob()"
    [excludeBeforeClick]="true"
    [exclude]="'#mobile-collapse'"
    id="sidebar-menu"
    [style.max-width]="'300px'"
    [style.max-height]="'calc(100vh - 56px)'"
  >
    <div class="navbar-content">
      <ul
        class="nav pcoded-inner-navbar"
        (clickOutside)="fireOutClick()"
      >
        <ng-container *ngFor="let item of navigation">
          <app-nav-group
            *ngIf="item.type == 'group'"
            [item]="item"
          ></app-nav-group>
          <app-nav-collapse
            *ngIf="item.type == 'collapse'"
            [item]="item"
          ></app-nav-collapse>
          <app-nav-item
            *ngIf="item.type == 'item'"
            [item]="item"
          ></app-nav-item>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
