<div class="row">
  <div class="col-md-12 order-md-1">
    <app-card [hidHeader]="true">
      <div class="card-title">
        <div class="d-flex justify-content-between">
          <div>
            <h5>Servicios contratados</h5>
            <p>Filtrar por servicios</p>
            <select
              class="form-select"
              aria-label="Seleccione un servicio"
            >
              <option selected>Todos</option>
              <option value="1">Internet fibra óptica</option>
            </select>
          </div>
        </div>
        <hr />
      </div>

      <div class="">
        <p class="fs-5">Detalles de planes</p>
        <div
          class="card"
          style="width: 18rem"
          *ngFor="let plan of hiredServices"
        >
          <div class="card-body text-center">
            <h5 class="card-title text-primary">
              <i class="feather icon-wifi"></i>
              {{ plan.nombrePlan.split('-')[0] }}
            </h5>
            <hr />
            <p class="text-primary fs-5">
              <strong>{{ plan.nombrePlan.split('-')[1] }}</strong>
            </p>
            <p class="card-text">
              Cargo mensual:
              <strong>S/ {{ plan.monthlyPrice }}</strong>
            </p>
            <!-- TODO: agregar cambio de color si esta suspendido -->
            <p class="fs-6 badge bg-success">{{ plan.status }}</p>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
