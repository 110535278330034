import { Injectable } from '@angular/core';
import { UserIdentity, UserIdentitySchema } from '../models/api-response/user';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from './api/api.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _route: Router, private _log: NGXLogger, private _api: ApiService) {}

  getIdentity(): UserIdentity {
    const stringIdentity = localStorage.getItem('identity');
    if (stringIdentity) {
      try {
        UserIdentitySchema.parse(JSON.parse(stringIdentity));
      } catch (e) {
        this._log.error(e);
      }
      return JSON.parse(stringIdentity) as UserIdentity;
    }
    this._route.navigate(['cliente/login']);
    throw new Error('sin');
  }

  getToken() {
    let token = localStorage.getItem('token');

    if (token && token != 'undefined') {
      return token;
    } else {
      return null;
    }
  }

  getHistoricChartData(userId: number): Observable<Historic> {
    return this._api.customer.getHistoric(userId).pipe(
      map((res) => {
        let filtered: Historic = { x: [], y: [] };
        res.data.forEach((e) => {
          filtered.x.push(e.shortSerieDescription);
          filtered.y.push(e.totalAmount);
        });
        return filtered;
      }),
    );
  }
}

type Historic = { y: number[]; x: string[] };
