import { HttpHeaders } from '@angular/common/http';
import camelcaseKeys from 'camelcase-keys';
import { map, Observable } from 'rxjs';
import {
  ClientReceiptsResponse,
  ClientReceiptsResponseSchema,
  FullApiResponse,
  MyPlansResponse,
  MyPlansResponseSchema,
} from 'src/app/models/api-response';
import {
  CustomerServicesResponse,
  CustomerServicesResponseSchema,
  CustomerTotalDebt,
  CustomerTotalDebtSchema,
} from 'src/app/models/api-response/customer';
import { CustomerHistoric, CustomerHistoricSchema } from 'src/app/models/api-response/customer/historic';
import {
  LoginResponse,
  LoginResponseSchema,
  UpdateUserResponse,
  UpdateUserResponseSchema,
} from 'src/app/models/api-response/user';
import { Login } from 'src/app/models/login';
import { User } from 'src/app/models/user';
import { parseResponse } from '../parse-response.operator';
import { BaseApiEndpoint } from './base-endpoint';

export class CustomerEndpoint extends BaseApiEndpoint {
  login(user: Login): Observable<LoginResponse> {
    let body = 'json=' + JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http
      .post<LoginResponse>(`${this.path}login`, body, { headers })
      .pipe(parseResponse(LoginResponseSchema));
  }

  updateUser(userData: User, id: number): Observable<UpdateUserResponse> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http
      .put<UpdateUserResponse>(`${this.path}user/${id}`, userData, { headers })
      .pipe(parseResponse(UpdateUserResponseSchema));
  }

  getUser(id: number): Observable<User> {
    return this._http.get<FullApiResponse>(`${this.path}user/${id}`).pipe(map((res) => camelcaseKeys(res.data)));
  }

  getTotalDebt(clientId: number) {
    return this._http
      .get<CustomerTotalDebt>(`${this.path}clients/${clientId}/total-debt`)
      .pipe(parseResponse(CustomerTotalDebtSchema));
  }

  getMyPlans(clientId: number) {
    return this._http
      .get<MyPlansResponse>(`${this.path}clients/${clientId}/plans`)
      .pipe(parseResponse(MyPlansResponseSchema));
  }

  getServices() {
    return this._http
      .get<CustomerServicesResponse>(this.path + 'services')
      .pipe(parseResponse(CustomerServicesResponseSchema));
  }

  // !!!: si pongo otro id de usuario me trae sus recibos, solo debería poder obtener mis propios recibos
  getReceipts(userId: number, service?: string) {
    return this._http
      .get<ClientReceiptsResponse>(`${this.path}clients/${userId}/receipts${service ? '?service=' + service : ''}`)
      .pipe(parseResponse(ClientReceiptsResponseSchema));
  }

  getHistoric(userId: number): Observable<CustomerHistoric> {
    return this._http
      .get<CustomerHistoric>(`${this.path}clients/${userId}/receipt-history`)
      .pipe(parseResponse(CustomerHistoricSchema));
  }

  getDedicatedServices(): Observable<any[]> {
    return this._http.get<any>(this.path + 'dedicated-services').pipe(map((res) => res.data));
  }
}
