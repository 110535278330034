import { Injectable } from '@angular/core';

type Level = 'info' | 'danger' | 'warning' | 'success';

interface ToastInfo {
  header: string;
  body: string;
  delay: number;
  level: Level;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];
  constructor() {}

  show(header: string, body: string, level: Level, delay = 4000) {
    this.toasts.push({ header, body, level, delay });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
