<div class="m-header">
  <a
    href="javascript:"
    class="mobile-menu"
    id="mobile-collapse"
    (click)="navCollapse()"
  >
    <span></span>
  </a>
  <a
    [routerLink]="['/dashboard/analytics']"
    class="b-brand"
  >
    <img
      id="main-logo"
      src="assets/images/logo.png"
      alt=""
      class="logo"
    />
    <!--<img src="assets/images/logo-icon.png" alt="" class="logo-thumb">-->
  </a>
  <a
    class="mob-toggler"
    [ngClass]="{ on: this.menuClass }"
    href="javascript:"
    (click)="toggleMobOption()"
  >
    <i class="feather icon-more-vertical"></i>
  </a>
</div>
<div
  class="collapse navbar-collapse"
  [style.display]="this.collapseStyle"
>
  <div class="d-flex justify-content-between w-100">
    <app-nav-left class=""></app-nav-left>
    <app-nav-right class=""></app-nav-right>
  </div>
</div>
