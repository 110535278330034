import { HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { CreatePayResponse, CreatePayResponseSchema } from 'src/app/models/api-response/customer';
import { ReceiptDetailResponse, ReceiptDetailResponseSchema } from 'src/app/models/api-response/receipt';
import { environment } from 'src/environments/environment';
import { parseResponse } from '../parse-response.operator';
import { BaseApiEndpoint } from './base-endpoint';
import { DataTablesResponse } from 'src/app/models/data-table-response';

export class UserGroupEndpoint extends BaseApiEndpoint {
  getUsersGroupedByUserGroup(body: any, groupId: number): Observable<DataTablesResponse> {
    return this._http.post<DataTablesResponse>(`${this.path}user-groups/${groupId}/grouped-users`, body, {});
  }

  getUserGroupsByToken(): Observable<any> {
    return this._http.get<any>(`${this.path}user-groups`);
  }

  getTraffic(): Observable<any> {
    return this._http.post<any>(`http://infistel.pe.devel/api/monitor-router/1`, {}, {});
  }
}
