import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePayResponse, CreatePayResponseSchema } from 'src/app/models/api-response/customer';
import { ReceiptDetailResponse, ReceiptDetailResponseSchema } from 'src/app/models/api-response/receipt';
import { environment } from 'src/environments/environment';
import { parseResponse } from '../parse-response.operator';
import { BaseApiEndpoint } from './base-endpoint';

export class ClientReceiptEndpoint extends BaseApiEndpoint {
  update(reciboCliente: any, id: number): Observable<any> {
    let json = JSON.stringify(reciboCliente);
    let params = 'json=' + json;
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.put(environment.API_URL + 'recibo-cliente/' + id, params, { headers });
  }

  getClientReceiptDetail(receiptId: number) {
    return this._http
      .get<ReceiptDetailResponse>(environment.API_URL + 'recibo-cliente-detalle/' + receiptId)
      .pipe(parseResponse(ReceiptDetailResponseSchema));
  }

  createPay(reciboClientePago: any) {
    let json = JSON.stringify(reciboClientePago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http
      .post<CreatePayResponse>(environment.API_URL + 'recibo-cliente-pago', params, { headers })
      .pipe(parseResponse(CreatePayResponseSchema));
  }

  updatePay(reciboClientePago: any, id: number): Observable<any> {
    let json = JSON.stringify(reciboClientePago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.put(environment.API_URL + 'recibo-cliente-pago/' + id, params, { headers });
  }
}
