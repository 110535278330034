import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated(): boolean {
    let token = localStorage.getItem('token');
    return token && token != 'undefined' ? true : false;
  }

  getIdentity() {
    const local = localStorage.getItem('identity');

    let identity = JSON.parse(local ? local : '');

    if (identity && identity != 'undefined') {
      return identity;
    } else {
      return null;
    }
  }

  getToken() {
    let token = localStorage.getItem('token');

    if (token && token != 'undefined') {
      return token;
    } else {
      return null;
    }
  }

  getRole(): number {
    const user = this.getIdentity();
    return user.rol;
  }

  getRoles(): number[] {
    const user = this.getIdentity();
    return user.roles;
  }

  isUserAllowed(allowedRoles: number[]) {
    if (allowedRoles.length === 0) {
      return true;
    }

    const roles = this.getRoles();
    return roles.some((role) => allowedRoles.includes(role));
  }

  getComponentPermissions(permissions: any) {
    let filtered: any = {};
    for (const prop in permissions) {
      if (this.isUserAllowed(permissions[prop])) {
        filtered[prop] = true;
      } else {
        filtered[prop] = false;
      }
    }

    return filtered;
  }

  isSchool(): boolean {
    const roles = this.getRoles();

    return roles.includes(6);
  }

  isUgel(): boolean {
    const roles = this.getRoles();

    return roles.includes(5);
  }
}
