import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { GradientConfig } from '../../../../../app-config';

import { UserService } from '../../../../../services/user.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
  ],
})
export class NavRightComponent implements OnInit, DoCheck {
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig = GradientConfig;

  public identity: any;
  public token: any;

  public url;

  constructor(public _user: UserService) {
    this.loadUser();
    this.url = environment.API_URL;

    this.visibleUserList = false;
    this.chatMessage = false;
  }

  ngOnInit() {}

  onChatToggle(friendID: any) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  loadUser() {
    this.identity = this._user.getIdentity();
    this.token = this._user.getToken();
  }

  ngDoCheck() {
    this.loadUser();
  }
}
