<div
  id="chart"
  *ngIf="chartOptions.series && chartOptions.chart && chartOptions.responsive && chartOptions.colors"
>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [responsive]="chartOptions.responsive"
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
