import { z } from 'zod';
import { BaseApiResponseSchema } from './base-api-response';

export const UserIdentitySchema = z.object({
  sub: z.number(),
  email: z.string().nullable(),
  name: z.string(),
  rol: z.number(),
  roles: z.array(z.number()),
  image: z.string(),
  nombres: z.string(),
  apellidos: z.string().optional(),
  iat: z.number(),
  exp: z.number(),
  celular: z.string().nullable().optional(),
});

export type UserIdentity = z.infer<typeof UserIdentitySchema>;

export const LoginResponseSchema = BaseApiResponseSchema.extend({
  data: z.object({
    token: z.string(),
    user: UserIdentitySchema,
  }),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export const UpdateUserResponseSchema = BaseApiResponseSchema.extend({
  message: z.string(),
});
export type UpdateUserResponse = z.infer<typeof UpdateUserResponseSchema>;

export const MyPlanSchema = z.object({
  id: z.number(),
  nombrePlan: z.string(),
  downloadSpeed: z.number(),
  uploadSpeed: z.number(),
  monthlyPrice: z.string(),
  planStartDate: z.string().nullable(),
  planEndDate: z.string().nullable(),
  reasonPlanChange: z.string().nullable(),
  status: z.string(),
});
export type MyPlan = z.infer<typeof MyPlanSchema>;
