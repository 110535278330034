import { Component, ViewChild } from "@angular/core";
import { ApexAxisChartSeries, ApexDataLabels, ApexFill, ApexPlotOptions, ApexTheme, ApexTooltip, ApexXAxis, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: string[];
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  theme: ApexTheme;
};

@Component({
  selector: 'app-dashboard-institution',
  templateUrl: './dashboard-institution.component.html',
  styleUrls: ['./dashboard-institution.component.scss']
})
export class DashboardInstitutionComponent {
  @ViewChild("chart") chart: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  conversion1!: Partial<ChartOptions>;
  conversion1Color = ['#73b4ff'];

  constructor() {
    this.chartOptions = {
      series: [44, 55],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["Activos", "Inactivos"],
      colors: ['#00E396', '#FF4560'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };

    this.conversion1 = {
      chart: {
        type: 'bar',
        height: 65,
        sparkline: {
          enabled: true
        },
        background: 'transparent'
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0,
          gradientToColors: ['#4099ff'],
          inverseColors: true,
          opacityFrom: 0.99,
          opacityTo: 0.99,
          stops: [0, 100]
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '80%'
        }
      },
      series: [
        {
          data: [
            25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54, 25, 66, 41, 89, 63, 54, 25, 66, 41, 85, 63, 25, 44, 12, 36, 9, 54, 25, 66, 41, 89,
            63, 25, 44, 12, 36, 9, 25, 44, 12, 36, 9, 54
          ]
        }
      ],
      xaxis: {
        crosshairs: {
          width: 1
        }
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        marker: {
          show: false
        }
      },
      theme: {
        mode: 'light'
      }
    };
  }

}
