<div class="row">
  <div class="col-md-12 order-md-1">
    <app-card [hidHeader]="true">
      <div class="card-title">
        <div class="d-flex justify-content-between">
          <div>
            <h5>Filtrar por servicios</h5>
            <select
              class="form-select"
              aria-label="Seleccione un servicio"
              (change)="onSelectServiceChange($any($event).target.value)"
            >
              <option
                selected
                [value]="null"
              >
                Todos
              </option>
              <option
                *ngFor="let service of serviceList; index as i"
                [value]="service.id"
              >
                {{ service.name }}
              </option>
            </select>
            <div class="d-md-none">
              <p class="m-0 pt-2">Deuda total:</p>
              <h5 class="m-0">{{ totalDebt | currency : 'S/. ' }}</h5>
            </div>
          </div>
          <div class="d-flex align-items-end flex-column justify-content-center">
            <div class="d-none d-md-block pb-2">
              <p class="m-0">Deuda total:</p>
              <h5 class="m-0">{{ totalDebt | currency : 'S/. ' }}</h5>
            </div>
            <a
              *ngIf="receiptSelected"
              class="btn btn-primary float-end fw-bold"
              role="button"
              (click)="toPay()"
            >
              Pagar {{ receiptSelected.totalAmount | currency : 'S/. ' }}
            </a>
          </div>
        </div>
        <hr />
      </div>

      <div *ngIf="clientReceiptList">
        <app-table-receipt
          [clientReceiptList]="clientReceiptList"
          (onReceiptSelected)="setReceiptSelected($event)"
        ></app-table-receipt>
      </div>
      <div
        *ngIf="!receiptSelected"
        class="alert alert-warning text-center"
        role="alert"
      >
        <strong>Seleccione un recibo a pagar</strong>
      </div>
    </app-card>
  </div>
</div>

<swal
  #alertEmail
  title="Registrar correo electrónico"
  text="Ud. no cuenta con un correo electrónico registrado, para realizar sus pagos registra tu correo en el formulario de información de contacto."
  icon="warning"
  [showConfirmButton]="true"
  [confirmButtonText]="'Aceptar'"
  [position]="'center'"
  (confirm)="editContactInformation()"
></swal>
