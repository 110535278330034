import { z } from 'zod';
import { MyPlanSchema } from './user';

const ApiResponseStatusSchema = z.enum(['success', 'error']);
export type ApiResponseStatus = z.infer<typeof ApiResponseStatusSchema>;

const BaseApiResponseSchema = z.object({
  code: z.number(),
  status: ApiResponseStatusSchema,
});
export type BaseApiResponse = z.infer<typeof BaseApiResponseSchema>;

const ServicioClienteSchema = z.object({
  id: z.number(),
  servicio_id: z.number(),
  usuario_id: z.number(),
  fecha_inicio: z.string(),
  fecha_fin: z.null(),
  codigo_ubigeo: z.string(),
  direccion: z.string(),
  referencias_domicilio: z.null(),
  detalles: z.null(),
  registrador: z.number(),
  responsables: z.null(),
  estado: z.string(),
});

const ServicioClienteInternetSchema = z.object({
  id: z.number(),
  servicio_cliente_id: z.number(),
  internet_plan_zona_id: z.null(),
  velocidad_bajada: z.number(),
  velocidad_subida: z.number(),
  precio_mensual: z.string(),
  fecha_inicio_plan: z.string(),
  fecha_fin_plan: z.null(),
  motivo_cambio_plan: z.null(),
  empresa_id: z.number(),
  estado: z.string(),
  servicio_cliente: ServicioClienteSchema,
});

const ReciboClienteItemSchema = z.object({
  id: z.number(),
  recibo_cliente_id: z.number(),
  servicio_cliente_id: z.null(),
  servicio_cliente_internet_id: z.number(),
  tipo_item: z.string(),
  descripcion: z.string(),
  monto: z.number(),
  mes: z.number(),
  fecha_registro: z.string().nullable(),
  fecha_actualizacion: z.string().nullable(),
  estado: z.string(),
  servicio_cliente_internet: ServicioClienteInternetSchema,
});

const PersonaDetalleSchema = z.object({
  id: z.number(),
  dni: z.string(),
  ruc: z.null(),
  apellidos: z.string(),
  nombres: z.string(),
  email: z.string(),
  celular: z.string(),
  celular2: z.null(),
  telefono_fijo: z.string(),
});

const UsuarioSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  image: z.string(),
  rol_usuario_id: z.number(),
  registrador: z.null(),
  fecha_registro: z.string(),
  fecha_actualizacion: z.string(),
  estado: z.string(),
  persona_detalle: PersonaDetalleSchema,
  persona_ruc_detalle: z.null(),
  empresa_detalle: z.null(),
  full_name: z.string(),
});

const FormaPagoDetalleSchema = z.object({
  id: z.number(),
  forma_pago_id: z.number(),
  empresa_id: z.null(),
  nombre_empresa: z.string(),
  comision_empresa_fija: z.string(),
  comision_empresa_porcentaje: z.string(),
  comision_cliente_fija: z.string(),
  comision_cliente_porcentaje: z.string(),
});

const ReciboClientePagoSchema = z.object({
  recibo_cliente_id: z.number(),
  forma_pago_detalle_id: z.number(),
  comprobante_detalle_id: z.null(),
  numero_comprobante: z.null(),
  fecha_vencimiento_comprobante: z.null(),
  fecha_pago: z.string(),
  codigo_operacion: z.string(),
  comision: z.string(),
  fecha_registro: z.string(),
  fecha_actualizacion: z.string(),
  estado: z.string(),
  comprobante_detalle: z.null(),
  forma_pago_detalle: FormaPagoDetalleSchema,
});

const ReciboClienteSchema = z.object({
  id: z.number(),
  userId: z.number(),
  fecha_emision: z.string(),
  fecha_vencimiento: z.string(),
  mes: z.number(),
  monto_total: z.number(),
  fecha_registro: z.string().nullable(),
  fecha_actualizacion: z.string().nullable(),
  registrador: z.null(),
  estado: z.string(),
  recibo_cliente_item: z.array(ReciboClienteItemSchema),
  recibo_cliente_pago: ReciboClientePagoSchema.nullable(),
  usuario: UsuarioSchema,
});
export type ReciboCliente = z.infer<typeof ReciboClienteSchema>;

const ClientReceiptItemSchema = z.object({
  amount: z.number(),
  description: z.string(),
  id: z.number(),
  itemType: z.string(),
  // !!!: el mes en el recibo 6 item 0 trae mes nulo con el cliente 1248, verificar si es posible que sea nuleable
  month: z.number().nullable(),
  monthDescription: z.string(),
  status: z.string(),
});

const ClientReceiptSchema = z.object({
  id: z.number(),
  expirationDate: z.string(),
  issueDate: z.string(),
  month: z.number(),
  monthDescription: z.string(),
  status: z.string(),
  totalAmount: z.number(),
  userId: z.number(),
  items: z.array(ClientReceiptItemSchema),
});
export type ClientReceipt = z.infer<typeof ClientReceiptSchema>;

export const ClientReceiptsResponseSchema = BaseApiResponseSchema.extend({
  data: z.array(ClientReceiptSchema),
});
export type ClientReceiptsResponse = z.infer<typeof ClientReceiptsResponseSchema>;

export const MyPlansResponseSchema = BaseApiResponseSchema.extend({
  data: z.array(MyPlanSchema),
});
export type MyPlansResponse = z.infer<typeof MyPlansResponseSchema>;

export interface FullApiResponse extends BaseApiResponse {
  message?: string;
  data?: any;
  draw?: number;
  recordsTotal?: number;
  recordsFiltered?: number;
  changes?: any;
  errors?: any;
}
