<div class="row">
  <div class="col-lg-12 col-md-12">
    <div class="card overflow-hidden h-details">
      <div class="card-body">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="active"
          class="nav-tabs"
        >
          <li [ngbNavItem]="1">
            <button ngbNavLink>EVENTOS</button>
            <ng-template ngbNavContent>
              <div class="fw-bolder mt-3">Total: {{ events.length }}</div>
              <table class="table table-primary mt-3">
                <thead>
                  <tr>
                    <th scope="col">Hora/Fecha</th>
                    <th scope="col">Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of events">
                    <td>{{ item.time }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button ngbNavLink>ADVERTENCIAS</button>
            <ng-template ngbNavContent>
              <div class="fw-bolder mt-3">Total: {{ warnings.length }}</div>
              <table class="table table-warning mt-3">
                <thead>
                  <tr>
                    <th scope="col">Hora/Fecha</th>
                    <th scope="col">Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of warnings">
                    <td>{{ item.time }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <button ngbNavLink>ERRORES</button>
            <ng-template ngbNavContent>
              <div class="fw-bolder mt-3">Total: {{ errors.length }}</div>
              <table class="table table-danger mt-3">
                <thead>
                  <tr>
                    <th scope="col">Hora/Fecha</th>
                    <th scope="col">Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of errors">
                    <td>{{ item.time }}</td>
                    <td>{{ item.message }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
        </ul>

        <div
          [ngbNavOutlet]="nav"
          class="mt-2"
        ></div>
      </div>
    </div>
  </div>
</div>
