<ul class="navbar-nav ms-auto">
  <li>
    <div
      class="dropdown drp-user"
      ngbDropdown
      placement="auto"
    >
      <a
        href="javascript:"
        ngbDropdownToggle
        data-toggle="dropdown"
      >
        <img
          src="{{ url + 'user/avatar/' + identity.image }}"
          class="img-radius wid-40"
          alt="User-Profile-Image"
        />
      </a>
      <div
        class="dropdown-menu dropdown-menu-end profile-notification"
        ngbDropdownMenu
      >
        <div class="pro-head">
          <div>
            <img
              src="{{ url + 'user/avatar/' + identity.image }}"
              class="img-radius"
              alt="User-Profile-Image"
            />
            <span *ngIf="identity && identity.name">{{ identity.nombres }}</span>
            <span *ngIf="!identity">Name</span>
          </div>
          <div>
            <a
              [routerLink]="['/cliente/logout/1']"
              class="dud-logout"
              title="Cerrar sesión"
            >
              <i class="feather icon-log-out"></i>
            </a>
          </div>
        </div>
        <ul class="pro-body">
          <li>
            <a
              [routerLink]="['/perfil']"
              class="dropdown-item"
            >
              <i class="feather icon-user"></i>
              Mi perfil
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
