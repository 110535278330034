import { z } from 'zod';
import { BaseApiResponseSchema } from '../base-api-response';

const CustomerHistoricItemSchema = z.object({
  id: z.number(),
  month: z.number(),
  year: z.number(),
  monthDescription: z.string(),
  shortSerieDescription: z.string(),
  totalAmount: z.number(),
});

export const CustomerHistoricSchema = BaseApiResponseSchema.extend({
  data: z.array(CustomerHistoricItemSchema),
});
export type CustomerHistoric = z.infer<typeof CustomerHistoricSchema>;
