import { z } from 'zod';
import { BaseApiResponseSchema } from './base-api-response';

//////
const ReceiptItemSchema = z.object({
  descripcion: z.string(),
  estado: z.string(),
  fecha_actualizacion: z.string().nullable(),
  fecha_registro: z.string().nullable(),
  id: z.number(),
  mes: z.number(),
  monto: z.string(),
  recibo_cliente_id: z.number(),
  servicio_cliente_internet_id: z.number(),
  tipo_item: z.string(),
});
export type ReceiptItem = z.infer<typeof ReceiptItemSchema>;
const ReceiptDetailSchema = z.object({
  estado: z.string(),
  fecha_actualizacion: z.string().nullable(),
  fecha_emision: z.string(),
  fecha_registro: z.string().nullable(),
  fecha_vencimiento: z.string(),
  id: z.number(),
  mes: z.number(),
  monto_total: z.string(),
  recibo_cliente_item: z.array(ReceiptItemSchema),
  usuario_id: z.number(),
});
export type ReceiptDetail = z.infer<typeof ReceiptDetailSchema>;
export const ReceiptDetailResponseSchema = BaseApiResponseSchema.extend({
  recibo_cliente: ReceiptDetailSchema,
});
export type ReceiptDetailResponse = z.infer<typeof ReceiptDetailResponseSchema>;
///////

const ChargeBodySchema = z.object({
  amount: z.number(),
  currency_code: z.string(),
  email: z.string().email(),
  metadata: z.object({
    dni: z.string(),
    mes: z.number(),
    recibo_id: z.number(),
    user_id: z.number(),
  }),
  source_id: z.string(),
});
export type ChargeBody = z.infer<typeof ChargeBodySchema>;
