import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAppGuard implements CanActivate {
  constructor(private _auth: AuthService, private _router: Router) {}

  canActivate(): any {
    if (this._auth.isAuthenticated()) {
      return true;
    } else {
      // Redireccion al main
      this._router.navigate(['cliente/login']);
    }
  }
}
