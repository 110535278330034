import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ClientReceipt } from 'src/app/models/api-response';
import { UserService } from 'src/app/services/user.service';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from 'src/app/services/api/api.service';
// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

@Component({
  selector: 'app-download-receipt',
  templateUrl: './download-receipt.component.html',
  styleUrls: ['./download-receipt.component.scss'],
})
export class DownloadReceiptComponent {
  @Input() receipt: ClientReceipt;

  constructor(private _user: UserService, private _api: ApiService) {}

  downloadReceipt() {
    this._api.customer.getUser(this._user.getIdentity().sub).subscribe({
      next: (res) => {
        this.generateReceiptPDF(res);
      },
    });
  }

  generateReceiptPDF(user: any) {
    let data = this.receipt;
    let nro_recibo = this.receipt.id;
    let dni_cliente = user.name;
    let nombre_cliente = user.userDetails.fullName;
    // let direccion_cliente = this.receipt.items[0]..recibo_cliente_item[0].servicio_cliente_internet.servicio_cliente.direccion;
    let mes_recibo = new Date(`2020-${this.receipt.month.toString().padStart(2, '0')}-28`).toLocaleString('es-PE', {
      month: 'long',
    });
    let departamento_cliente = 'HUÁNUCO';
    let estado_recibo = this.receipt.status;

    async function main() {
      const pdf = new PdfMakeWrapper();

      pdf.styles({
        urlText: {
          margin: [0, 40, 0, 0],
        },
        clienteText: {
          fontSize: 11,
          margin: [0, 0, 0, 20],
        },
        marginBottomRow: {
          margin: [0, 0, 0, 20],
        },
        marginHeader: {
          margin: [-10, -50, 0, 20],
        },

        marginEtiqueta: {
          margin: [385, -39, 0, 0],
        },
        detallesEmpresa: {
          margin: [-350, -45, 0, 40],
          alignment: 'center',
        },
      });

      let nombre_empresa = new Txt('Sercorsac').bold().fontSize(10).end;
      let servicio_internet = new Txt('Internet').bold().fontSize(10).end;
      let logo_empresa = await new Img('assets/images/logo-sercorsac-big.jpg')
        .fit([180, 164])
        .style('marginHeader')
        .build();
      let url_empresa = new Txt('www.infistel.pe').alignment('right').margin([0, -55, 0, 20]).end;
      let etiqueta_pendiente = await new Img('assets/images/etiqueta-recibo-pendiente.png')
        .fit([170, 330])
        .style('marginEtiqueta')
        .build();
      let etiqueta_pagado = await new Img('assets/images/etiqueta-recibo-pagado.png')
        .fit([170, 330])
        .style('marginEtiqueta')
        .build();
      let etiqueta_vencido = await new Img('assets/images/etiqueta-recibo-vencido.png')
        .fit([170, 330])
        .style('marginEtiqueta')
        .build();
      let ruc_empresa = new Txt('RUC: 20529084936').bold().color('#ce0521').fontSize(14).end;
      let companyAddress = new Txt('Jr. 28 de Julio Mz.D Lt.7').fontSize(11).end;
      let referencia_empresa = new Txt('Frente al colegio THALES').fontSize(11).end;

      let detalles_empresa = new Stack([ruc_empresa, companyAddress, referencia_empresa]).style('detallesEmpresa').end;

      let receiptDescription = new Txt('Internet Inalámbrico').end;
      let periodo_recibo = new Txt('Periodo: ' + mes_recibo).end;
      let detalles_recibo = new Stack([receiptDescription, periodo_recibo]).style('marginBottomRow').end;

      let formas_pago = new Stack([
        new Txt('Formas de pago:').bold().margin([0, 0, 0, 2]).end,
        new Txt(['Banco BCP: A nombre de la empresa ', nombre_empresa, ' y del servicio de ', servicio_internet, '.'])
          .end,
        new Txt([
          'Agente BCP: A nombre de la empresa ',
          nombre_empresa,
          ' con el Nro. de agente 09254, luego el servicio de ',
          servicio_internet,
          '.',
        ]).end,
        new Txt([
          'Banca por internet BCP:  En "Pago de Servicios" busque la empresa ',
          nombre_empresa,
          ' luego el servicio de ',
          servicio_internet,
          '.',
        ]).end,
        new Txt([
          'Página web: Ingresar a nuestra plataforma https://www.infistel.pe e iniciar sesión para hacer pagos.',
        ]).end,
      ])
        .margin([0, 10, 0, 0])
        .fontSize(10).end;

      let publicidad = new Txt(
        'También ofrecemos Venta de Productos y servicios de Mantenimiento de Equipos de Computo, Redes, cámaras de vigilancia, smartphones y otros equipos relacionados',
      ).alignment('center').end;
      let eslogan = new Txt('Sercorsac busca la mejora de la Conexión a Internet llegando a lugares sin Cobertura')
        .margin([0, 20, 0, 0])
        .alignment('center')
        .bold()
        .fontSize(14)
        .italics().end;

      let label_correos = new Txt('Email:').end;
      let labelPhones = new Txt('Teléfono:').end;
      let correos = new Stack(['sercorsac@outlook.com', 'sercor.sac.hco@gmail.com', 'sercorsac@outlook.com']).end;
      let phones = new Stack(['962702722', '948939227', '062-281533']).end;
      let correos_detalles = new Columns([label_correos, correos]).columnGap(-50).end;
      let phonesDetails = new Columns([labelPhones, phones]).fontSize(11).end;

      pdf.pageSize('A4');

      if (estado_recibo == 'PENDIENTE') {
        pdf.add(etiqueta_pendiente);
      } else if (estado_recibo == 'PAGADO') {
        pdf.add(etiqueta_pagado);
      } else {
        pdf.add(etiqueta_vencido);
      }

      pdf.add(logo_empresa);

      pdf.add(detalles_empresa);

      pdf.add(url_empresa);

      pdf.add(
        new Table([
          [new Cell(new Txt('Recibo ' + nro_recibo).bold().fontSize(14).end).fillColor('#e5e7e9').end],
          [
            new Cell(new Txt('Fecha de emisión: ' + formatDate(data.issueDate, 'dd/MM/yyyy', 'en-US')).end).fillColor(
              '#e5e7e9',
            ).end,
          ],
          [
            new Cell(
              new Txt('Fecha de vencimiento: ' + formatDate(data.expirationDate, 'dd/MM/yyyy', 'en-US')).end,
            ).fillColor('#e5e7e9').end,
          ],
        ])
          .widths(['100%'])
          .layout('noBorders')
          .style('marginBottomRow').end,
      );

      pdf.add(
        new Columns([
          new Stack([
            new Txt('Cliente').bold().fontSize(13).end,
            dni_cliente,
            nombre_cliente,
            // direccion_cliente,
            departamento_cliente,
          ]).style('clienteText').end,
          //new Txt('Forma de pago: ' + forma_pago).alignment('right').end
        ]).end,
      );

      pdf.add(
        new Table([
          [
            new Cell(new Txt('Detalles de pago').end).fillColor('#e5e7e9').alignment('center').end,
            new Cell(new Txt('Importe').end).fillColor('#e5e7e9').alignment('center').end,
          ],
          [detalles_recibo, new Txt('S/. ' + data.totalAmount).alignment('center').end],
          [new Txt('Sub total').alignment('right').end, new Txt('S/. ' + data.totalAmount).alignment('center').end],
          [new Txt('Total').alignment('right').end, new Txt('S/. ' + data.totalAmount).alignment('center').end],
        ]).widths(['80%', '20%']).end,
      );

      pdf.add(formas_pago);

      pdf.add(
        new Table([
          [new Cell(publicidad).fillColor('#e5e7e9').colSpan(2).end, new Txt('').bold().end],
          [correos_detalles, phonesDetails],
        ])
          .layout({
            hLineColor: function (_, _1) {
              return '#6c757d';
            },

            vLineColor: function (_, _1) {
              return '#6c757d';
            },
          })
          .margin([0, 60, 0, 0])
          .widths(['50%', '50%']).end,
      );

      pdf.add(eslogan);

      pdf.create().open();
    }

    main();
  }
}
