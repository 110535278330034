<div class="row">
  <div class="col-sm-12">
    <app-card
      [hidHeader]="true"
      cardClass="data-node-list data-tables-server-side"
    >
      <div class="card-title">
        <div class="d-flex justify-content-between">
          <div>
            <select
              class="form-select"
              aria-label="Seleccione una UGEL"
              [(ngModel)]="groupId"
            >
              <ng-container *ngFor="let item of groups">
                <option value="{{ item.id }}">{{ item.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <hr />
      </div>

      <div
        class="dt-responsive table-responsive"
        *ngIf="groupId"
      >
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table table-striped row-border table-hover"
          id="institutionsTable"
          style="width: 100%"
          aria-labelledby=""
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th
                scope="col"
                style="width: 123px"
              >
                Código Modular
              </th>
              <th scope="col">Nombre</th>
              <th scope="col">Localidad</th>
              <th scope="col">IP</th>
              <th
                scope="col"
                class="text-center"
              >
                Tráfico (Subida/Bajada)
              </th>
              <th
                scope="col"
                class="text-center"
              >
                Estado
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of institutions; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ data.name }}</td>
              <td>{{ data.userDetails.name }}</td>
              <td>
                <ng-container *ngIf="data.ubigeoDetail">
                  {{ data.ubigeoDetail.district.name }} - {{ data.ubigeoDetail.province.name }} -
                  {{ data.ubigeoDetail.departament.name }}
                </ng-container>
              </td>
              <td>{{ data?.institutionInterface?.ip }}</td>
              <td class="text-center">
                <ng-container *ngIf="data.trafficData === undefined">
                  <div
                    role="status"
                    class="spinner-border spinner-border-sm"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.trafficData && !data.trafficData?.error">
                  {{ data.trafficData.tx }}/{{ data.trafficData.rx }}
                </ng-container>
              </td>
              <td class="text-center">
                <ng-container *ngIf="data.trafficData === undefined">
                  <div
                    role="status"
                    class="spinner-border spinner-border-sm"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.trafficData && !data.trafficData?.error">
                  <div class="d-flex">
                    <span class="badge text-bg-success state-txt">ACTIVO</span>
                    <div
                      class="spinner-grow spinner-grow-sm text-success mb-spiner ms-1"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.trafficData === null || data.trafficData?.error">
                  <div class="d-flex">
                    <span class="badge text-bg-danger state-txt">INACTIVO</span>
                    <div
                      class="spinner-grow spinner-grow-sm text-danger mb-spiner ms-1"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="institutions?.length == 0">
            <tr>
              <td
                colspan="7"
                class="no-data-available"
              >
                No se encontraron resultados!
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Código Modular</th>
              <th scope="col">Nombre</th>
              <th scope="col">Localidad</th>
              <th scope="col">IP</th>
              <th
                scope="col"
                class="text-center"
              >
                Tráfico (Subida/Bajada)
              </th>
              <th
                scope="col"
                class="text-center"
              >
                Estado
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </app-card>
  </div>
</div>
