import camelcaseKeys from 'camelcase-keys';
import {
  PaymentMethodDetailResponse,
  PaymentMethodDetailResponseSchema,
} from 'src/app/models/api-response/payment-method';
import { parseResponse } from '../parse-response.operator';
import { BaseApiEndpoint } from './base-endpoint';

export class PaymentMethodDetailEndpoint extends BaseApiEndpoint {
  getDetail(id: number) {
    return this._http
      .get<PaymentMethodDetailResponse>(this.path + id)
      .pipe(parseResponse(PaymentMethodDetailResponseSchema));
  }
}
