<div
  (click)="onContainerClicked($event)"
  class="basic modal fade"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  [ngClass]="{ in: visibleAnimate }"
  [ngStyle]="{
    display: visible ? 'block' : 'none',
    opacity: visibleAnimate ? 1 : 0
  }"
>
  <div
    class="modal-dialog"
    role="document"
    [ngClass]="dialogClass"
  >
    <div class="modal-content">
      <div
        *ngIf="!hideHeader"
        class="modal-header"
      >
        <ng-content select=".app-modal-header"></ng-content>
      </div>
      <div class="modal-body">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div
        *ngIf="!hideFooter"
        class="modal-footer"
      >
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
