import { Component, OnInit } from '@angular/core';
import { GradientConfig } from '../../../app-config';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  gradientConfig = GradientConfig;
  navCollapsed: boolean;
  navCollapsedMob: boolean;
  breakpoint = 992;

  constructor() {
    if (window.innerWidth >= this.breakpoint && window.innerWidth <= 1024) {
      this.gradientConfig.collapseMenu = true;
    }

    this.navCollapsed = window.innerWidth >= this.breakpoint ? this.gradientConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    if (window.innerWidth < this.breakpoint) {
      setTimeout(() => {
        document.querySelector('.pcoded-navbar')?.classList.add('menupos-static');
        (document.querySelector('#sidebar-menu') as HTMLElement).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }
  }

  navMobClick() {
    if (window.innerWidth < this.breakpoint) {
      if (
        this.navCollapsedMob &&
        !document.querySelector('app-navigation.pcoded-navbar')?.classList.contains('mob-open')
      ) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }
}
