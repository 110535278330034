import { z } from 'zod';
import { BaseApiResponseSchema } from './base-api-response';

export const CulqiChargeResponseSchema = z.object({
  creation_date: z.number(),
  id: z.string(),
});
export type CulqiChargeResponse = z.infer<typeof CulqiChargeResponseSchema>;

/// Exchange rate
const CulqiExchangeRate = z.object({
  compra: z.number(),
  fecha: z.string(),
  moneda: z.string(),
  origen: z.string(),
  venta: z.number(),
});
export const CulqiExchangeRateResponseSchema = BaseApiResponseSchema.extend({
  data: CulqiExchangeRate,
});
export type CulqiExchangeRateResponse = z.infer<typeof CulqiExchangeRateResponseSchema>;
