import { ApiUtils } from './api.utils';
import { DateUtils } from './date.utils';

class UtilsWrapper {
  date: DateUtils;
  api: ApiUtils;

  constructor() {
    this.date = new DateUtils();
    this.api = new ApiUtils();
  }
}

export const Utils = new UtilsWrapper();
