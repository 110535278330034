//Imports necesarios
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Importar componentes
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthAppGuard } from './guards/auth-app.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { InicioComponent } from './components/client/inicio/inicio.component';
import { PerfilComponent } from './components/client/perfil/perfil.component';
import { ServiciosComponent } from './components/client/servicios/servicios.component';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { ReceiptComponent } from './components/client/receipt/receipt.component';
import { ContactComponent } from './components/client/contact/contact.component';
import { InstitutionsComponent } from './components/client/institutions/institutions.component';
import { DashboardInstitutionComponent } from './components/client/dashboard-institution/dashboard-institution.component';
import { NotificationsComponent } from './components/client/notifications/notifications.component';
import { DashboardDedicatedComponent } from './components/client/dashboard-dedicated/dashboard-dedicated.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'cliente/login',
        pathMatch: 'full',
        title: 'Iniciar Sesión',
      },
      {
        path: 'cliente',
        loadChildren: () => import('./components/auth/auth.module').then((module) => module.AuthModule),
      },
    ],
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'pagos/:recibo',
        component: PaymentGatewayComponent,
        canActivate: [AuthAppGuard],
        title: 'Pagar Recibo',
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [AuthAppGuard],
        title: 'Mi Perfil',
      },
      {
        path: 'dashboard-institucion',
        component: DashboardInstitutionComponent,
        canActivate: [AuthAppGuard],
        title: 'Inicio',
      },
      {
        path: 'dashboard-dedicado',
        component: DashboardDedicatedComponent,
        canActivate: [AuthAppGuard],
        title: 'Inicio',
      },
      {
        path: 'inicio',
        component: InicioComponent,
        canActivate: [AuthAppGuard],
        title: 'Inicio',
      },
      {
        path: 'notificaciones',
        component: NotificationsComponent,
        canActivate: [AuthAppGuard],
        title: 'Inicio',
      },
      {
        path: 'recibos-y-pagos',
        component: ReceiptComponent,
        canActivate: [AuthAppGuard],
        title: 'Mis Recibos y Pagos',
      },
      {
        path: 'servicios',
        component: ServiciosComponent,
        canActivate: [AuthAppGuard],
        title: 'Mis Servicios',
      },
      {
        path: 'servicios-ie',
        component: InstitutionsComponent,
        canActivate: [AuthAppGuard],
        title: 'Mis Instituciones',
      },
      {
        path: 'contacto',
        component: ContactComponent,
        canActivate: [AuthAppGuard],
        title: 'Contáctenos',
      },
    ],
  },
];

// secciones a futuro:
// - soporte
// - notificaciones

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
