import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  logs: any[] = [];
  services: any[] = [];
  service: any;
  nodeId!: number;
  intervalSub: any;
  active: number = 1;
  events: any[] = [];
  warnings: any[] = [];
  errors: any[] = [];

  constructor(private _api: ApiService) {}

  ngOnInit(): void {
    this.getDedicatedServices();

    this.intervalSub = setInterval(() => {
      if (this.nodeId) {
        this.getLogs(this.nodeId);
      }
    }, 60000);
  }

  getDedicatedServices() {
    this._api.customer.getDedicatedServices().subscribe({
      next: (resp) => {
        this.services = resp;
        this.service = resp.length > 0 ? resp[0] : null;
        this.nodeId = this.service.dedicatedService?.nodeId;

        this.getLogs(this.nodeId);
      },
      error: () => {},
    });
  }

  getLogs(nodeId: number) {
    this._api.router.getRouterLogs(nodeId).subscribe({
      next: (resp) => {
        this.logs = resp;
        this.events = this.setLogs('info');
        this.warnings = this.setLogs('warning');
        this.errors = this.setLogs('error');
      },
      error: () => {},
    });
  }

  setLogs(type: string) {
    return this.logs.filter((log) => log.topics.includes(type));
  }

  ngOnDestroy() {
    if (this.intervalSub) {
      clearInterval(this.intervalSub);
    }
  }
}
