import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { UserService } from '../../../services/user.service';

import { Company, Person, User, UserDetails } from '../../../models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReciboCliente } from 'src/app/models/api-response';
import { UserIdentity } from 'src/app/models/api-response/user';
import { ApiService } from 'src/app/services/api/api.service';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cli-perfil',
  templateUrl: './perfil.component.html',
})
export class PerfilComponent implements OnInit, OnDestroy {
  activeTab = 'profile';

  editProfile = false;
  editProfileIcon = 'icon-edit';

  editContact = false;
  editContactIcon = 'icon-edit';

  editOtherInfo = false;
  editOtherInfoIcon = 'icon-edit';

  /**unsubscribe before leave this component*/
  private ngUnsubscribe = new Subject();

  identity: UserIdentity;

  usuario_id: number; // id del usuario actual
  data_saved_successfully: boolean; // indica si se guardaron correctamente los datos a la BD
  registration_successful_message?: string = undefined;
  registration_error_message?: string = undefined;

  user: User;
  person: Person;
  company: Company;

  form: FormGroup;
  isDelay: boolean;
  isSchool: boolean = false;
  isUgel: boolean = false;

  constructor(
    private _user: UserService,
    private formBuilder: FormBuilder,
    private _api: ApiService,
    private _log: NGXLogger,
    private route: ActivatedRoute,
    private location: Location,
    private _authService: AuthService,
  ) {
    this.form = this.formBuilder.group({
      cellphone1: ['', Validators.required],
      cellphone2: [''],
      phone: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([a-zA-Z0-9\\.\\-_]+\\@[a-zA-Z0-9\\-_]+\\.([a-zA-Z0-9\\-_]+\\.)?[a-zA-Z]+(\\.[a-zA-Z]+)?)?$',
          ),
        ],
      ],
    });

    this.identity = this._user.getIdentity();

    this.isSchool = this._authService.isSchool();
    this.isUgel = this._authService.isUgel();
  }

  get emailInvalid() {
    return this.form.get('email')?.touched && this.form.get('email')?.errors;
  }

  get cellphone1Invalid() {
    return this.form.get('cellphone1')?.touched && this.form.get('cellphone1')?.errors;
  }

  ngOnInit() {
    this.getUser(this.identity.sub);
    document.querySelector('body')?.classList.remove('modal-open');
  }

  setForm(user: User) {
    this.form.setValue({
      cellphone1: user.userDetails?.cellPhone,
      cellphone2: user.userDetails?.cellPhone2,
      phone: user.userDetails?.phone,
      email: user.userDetails?.email,
    });
  }
  isEnabled(data: ReciboCliente[], receiptId: number): boolean {
    let index = null;
    let enabled = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == receiptId) index = i;

      if (index != null && i > index && data[i].estado == 'VENCIDO') {
        enabled = false;
        break;
      }
    }

    return enabled;
  }

  getUser(id: number) {
    this._api.customer.getUser(id).subscribe((res) => {
      this.user = res;
      this.setForm(this.user);
      this.identity.celular = this.user.userDetails?.cellPhone;
      localStorage.setItem('identity', JSON.stringify(this.identity));

      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams['email']) {
          this.editContactInformation();
        }
      });
    });
  }

  getPerson(userDetail: UserDetails): Person {
    return {
      id: userDetail.id,
      dni: userDetail.dni,
      ruc: userDetail.ruc,
      lastName: userDetail.lastName || '',
      firstName: userDetail.firstName || '',
      email: userDetail.email,
      cellPhone: userDetail.cellPhone,
      cellPhone2: userDetail.cellPhone2,
      phone: userDetail.phone,
    };
  }

  getCompany(userDetail: UserDetails): Company {
    return {
      id: userDetail.id,
      ruc: userDetail.ruc || '',
      name: userDetail.name || '',
      businessName: userDetail.businessName || '',
      email: userDetail.email,
      cellPhone: userDetail.cellPhone,
      cellPhone2: userDetail.cellPhone2,
      phone: userDetail.phone,
    };
  }

  onSubmit() {
    const data = this.form.value;
    if (this.form.valid) {
      if (this.user.userDetails) {
        this.user.userDetails.cellPhone = data.cellphone1;
        this.user.userDetails.cellPhone2 = data.cellphone2;
        this.user.userDetails.phone = data.phone;
        this.user.userDetails.email = data.email;
      }

      const body: User = {
        email: data.email,
        userType: this.user.userType,
        userDetails: this.user.userDetails,
      };

      this.isDelay = true;

      this._api.customer.updateUser(body, this.user.id || 0).subscribe({
        next: () => {
          this.isDelay = false;
          this.editContact = false;
          this.editContactIcon = 'icon-edit';
          if (this.user.userDetails?.email) this.identity.email = this.user.userDetails.email;

          localStorage.setItem('identity', JSON.stringify(this.identity)); //actualizar datos del usuario en la sesión

          this.data_saved_successfully = true;
          this.registration_successful_message = 'Se registraron los cambios satisfactoriamente.';
          this.clearQueryParam();
        },
        error: (error) => {
          this.isDelay = false;
          this.registration_error_message = 'Ocurrió un error en el registro.';
          this.data_saved_successfully = false;
          this._log.error(error);
        },
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  clearQueryParam() {
    const currentUrl = this.location.path();
    const urlWithoutQueryParams = currentUrl.split('?')[0];
    this.location.replaceState(urlWithoutQueryParams);
  }

  editContactInformation() {
    this.editContact = true;
    this.editContactIcon = 'icon-x';
    this.form.markAllAsTouched();
  }

  resetValues() {
    this.registration_successful_message = undefined;
    this.registration_error_message = undefined;
    this.data_saved_successfully = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
