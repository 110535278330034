import { z } from 'zod';
import { BaseApiResponseSchema } from './base-api-response';

const Services = z.object({
  id: z.number(),
  name: z.string(),
  serviceCategory: z.number(),
  status: z.string(),
});

export const CustomerServicesResponseSchema = BaseApiResponseSchema.extend({
  servicio: z.array(Services),
});
export type CustomerServicesResponse = z.infer<typeof CustomerServicesResponseSchema>;

export const CustomerTotalDebtSchema = BaseApiResponseSchema.extend({
  data: z.number(),
});
export type CustomerTotalDebt = z.infer<typeof CustomerTotalDebtSchema>;

const CreatePay = z.object({
  codigo_operacion: z.string(),
  comision: z.number(),
  comprobante_detalle_id: z.any().nullable(),
  estado: z.string(),
  fecha_actualizacion: z.string(),
  fecha_pago: z.string(),
  fecha_vencimiento_comprobante: z.any().nullable(),
  forma_pago_detalle_id: z.number(),
  numero_comprobante: z.any().nullable(),
  recibo_cliente_id: z.number(),
});
export const CreatePayResponseSchema = BaseApiResponseSchema.extend({
  data: CreatePay,
  message: z.string(),
});
export type CreatePayResponse = z.infer<typeof CreatePayResponseSchema>;
