import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NGXLogger } from 'ngx-logger';
import { ClientReceipt } from 'src/app/models/api-response';
import { UserIdentity } from 'src/app/models/api-response/user';
import { Company, Person, User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user.service';

export interface ClientReceiptMarked extends ClientReceipt {
  toPay: boolean;
}

@Component({
  selector: 'app-recibos',
  templateUrl: './receipt.component.html',
})
export class ReceiptComponent implements OnInit {
  receiptSelected: ClientReceiptMarked | undefined;
  user: User;
  person: Person;
  company: Company;
  totalDebt = 0;
  serviceList: { id: number; name: string; serviceCategory: number; status: string }[] = [];
  identity: UserIdentity;
  clientReceiptList: ClientReceipt[];

  @ViewChild('alertEmail') private alertEmail: SwalComponent;

  constructor(private _user: UserService, private router: Router, private _api: ApiService, private _log: NGXLogger) {
    this.identity = this._user.getIdentity();
    this.getTotalDebt();
    this.getServiceList();
    this.getClientReceiptList(this.identity.sub);
  }

  ngOnInit(): void {
    this.getUser(this.identity.sub);
  }

  onSelectServiceChange(serviceID: string): void {
    if (serviceID === 'null') {
      this.getClientReceiptList(this.identity.sub);
    } else {
      this.getClientReceiptList(this.identity.sub, serviceID);
    }
  }

  getClientReceiptList(clientId: number, service?: string): void {
    this._api.customer.getReceipts(clientId, service).subscribe({
      next: (res) => {
        this.clientReceiptList = [...res.data];
      },
      error: (error) => {
        this._log.error(error);
      },
    });
  }

  getTotalDebt(): void {
    this._api.customer.getTotalDebt(this.identity.sub).subscribe({
      next: (res) => {
        this.totalDebt = res.data;
      },
    });
  }

  toPay(): void {
    if (!this.receiptSelected) return;

    if (!this.user.userDetails?.email) {
      this.alertEmail.fire();
    } else {
      this.router.navigate(['pagos/' + this.receiptSelected.id]);
    }
  }

  getUser(id: number): void {
    this._api.customer.getUser(id).subscribe((res) => {
      this.user = res;
      this.identity.celular = this.user.userDetails?.cellPhone;
      localStorage.setItem('identity', JSON.stringify(this.identity));
    });
  }

  getServiceList(): void {
    this._api.customer.getServices().subscribe({
      next: (res) => {
        let onlyService = res.servicio[0];
        this.serviceList = [onlyService];
      },
    });
  }

  setReceiptSelected(receipt: ClientReceiptMarked | undefined): void {
    setTimeout(() => {
      this.receiptSelected = receipt;
    }, 0);
  }

  editContactInformation() {
    const queryParams = {
      email: 'update',
    };

    this.router.navigate(['perfil/'], { queryParams });
  }
}
