import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GradientConfig } from '../../../../app-config';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationComponent implements OnInit {
  public gradientConfig = GradientConfig;

  scroll = (): void => {
    if (document.querySelector('.pcoded-navbar')?.hasAttribute('style')) {
      document.querySelector('.pcoded-navbar.menupos-fixed')?.removeAttribute('style');
    }
  };

  constructor() {}

  ngOnInit() {
    document.querySelector('.pcoded-navbar')?.classList.add(this.gradientConfig.layoutType);
    document.querySelector('.pcoded-header')?.classList.add(this.gradientConfig.headerBackColor);
    setTimeout(() => {
      document.querySelector('.pcoded-navbar')?.classList.remove('menupos-static');
      document.querySelector('.pcoded-navbar')?.classList.add('menupos-fixed');
      (document.querySelector('#sidebar-menu') as HTMLElement).style.maxHeight = 'calc(100vh - 56px)'; // calc(100vh - 70px) amit
      window.addEventListener('scroll', this.scroll, true);
      window.scrollTo(0, 0);
    }, 100);

    document.querySelector('.pcoded-header')?.classList.add('headerpos-fixed');
    document.querySelector('body')?.classList.remove('box-layout');
    document.querySelector('body')?.classList.remove('container');
  }
}
