import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import * as snakecaseKeys from 'snakecase-keys';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(private _log: NGXLogger) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqClone = req.clone();
    let body = req.body;

    if (body && req.headers.get('Content-Type') === 'application/x-www-form-urlencoded' && typeof body === 'string') {
      const newBody: string = body;
      if (newBody.split('=')[0] == 'json') {
        body = this.changeBody(body);
      }
    }

    const tokenSent = req.headers.get('Authorization');
    if (!tokenSent) {
      const localToken = localStorage.getItem('token');
      if (localToken) {
        reqClone = req.clone({
          body: body,
          setHeaders: {
            authorization: localToken,
          },
        });
      }
    } else {
      reqClone = req.clone({
        body: body,
      });
    }

    // return next.handle(reqClone).pipe(catchError(this.manageError));
    return next.handle(reqClone).pipe(
      map((res: any) => {
        if (res instanceof HttpResponse) {
        }
        return res;
      }),
    );
  }

  manageError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error) {
      // client-side error
      errorMessage = `Client-side error: ${error.status} ${error.error.message}`;
      this._log.error(errorMessage);
    } else {
      // backend error
      if (error.error && error.error.message) {
        errorMessage = error.error.message;
        this._log.error(errorMessage);
      }
    }
    this._log.warn(errorMessage);
    return throwError('error al procesar');
  }

  changeBody(body: string): string {
    const json = JSON.parse(body.slice(5));
    const jsonSnakeCase = snakecaseKeys(json);
    return 'json=' + JSON.stringify(jsonSnakeCase);
  }
}
