import { Component } from '@angular/core';
import { MyPlan } from 'src/app/models/api-response/user';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
})
export class ServiciosComponent {
  hiredServices: MyPlan[];
  user: User;

  constructor(private _user: UserService, private _api: ApiService) {
    this._api.customer.getUser(this._user.getIdentity().sub).subscribe({
      next: (res) => {
        if (res.id) {
          this._api.customer.getMyPlans(res.id).subscribe({
            next: (res) => {
              this.hiredServices = res.data;
            },
          });
        }
      },
    });
  }
}
