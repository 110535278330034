<div class="row">
  <div class="col-md-4 col-12 col-sm-6">
    <app-card [hidHeader]="true">
      <div class="card-contact">
        <div class="icon bg-primary">
          <i class="feather icon-mail"></i>
        </div>
        <h3>Correo</h3>
        <ul>
          <li>
            <p>
              <a href="mailto:{{ business.email }}">{{ business.email }}</a>
            </p>
          </li>
        <li>
          <p>
            <a href="mailto:ventas@infistel.pe">ventas@infistel.pe</a>
          </p>
        </li>
        </ul>
      </div>
    </app-card>
  </div>
  <div class="col-md-4 col-12 col-sm-6">
    <app-card [hidHeader]="true">
      <div class="card-contact">
        <div class="icon bg-primary">
          <i class="feather icon-map-pin"></i>
        </div>
        <h3>Dirección</h3>
        <a
          [href]="business.linkToMaps"
          target="blank"
          class="hover:font-bold"
        >
          <p>{{ getAddress() }}</p>
        </a>
      </div>
    </app-card>
  </div>
  <div class="col-md-4 col-12 col-sm-6">
    <app-card [hidHeader]="true">
      <div class="card-contact">
        <div class="icon bg-primary">
          <i class="feather icon-phone"></i>
        </div>
        <h3>Teléfono</h3>
        <ul>
          <li>
            <p>
              <a href="tel:+51{{ business.phone[0] }}">{{ business.getFormattedPhone() }}</a>
            </p>
          </li>
          <li>
            <p>
              <a href="tel:+51{{ business.phone[1] }}">{{ business.phone[1] }}</a>
            </p>
            <p>(Opción 1: Ventas</p>
            <p class="ps-2">Opción 2: Soporte)</p>
          </li>
        </ul>
      </div>
    </app-card>
  </div>
</div>
