import { HttpClient } from '@angular/common/http';

/** Entidad base para crear un endpoint  */
export class BaseApiEndpoint {
  protected path: string;
  protected _http: HttpClient;

  constructor({ _httpClient, path }: EndpointConfig) {
    this._http = _httpClient;
    this.path = path;
  }
}

interface EndpointConfig {
  _httpClient: HttpClient;
  path: string;
}
