<ng-container *ngIf="responseStatus == 'success' && paymentStatus == null">
  <div class="card">
    <div class="ms-1 mt-1">
      Tipo de cambio:
      <span class="fw-bold ms-1">{{ exchangeRate | currency : 'S/. ' }}</span>
      <div
        class="spinner-grow spinner-grow-sm text-success mb-spiner ms-1"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="payment-method d-flex flex-column">
            <p>Medio de pago</p>
            <select
              class="form-select mb-2"
              style="width: fit-content"
              aria-label="Default select example"
              #t
              (change)="changeSelect(t.value)"
            >
              <option
                disabled
                selected
                hidden
              >
                -- SELECCIONE --
              </option>
              <option
                *ngFor="let payment of paymentTypes"
                [value]="payment.value"
              >
                {{ payment.text }}
              </option>
            </select>
            <p [innerHTML]="paymentSelected?.helpText"></p>
            <div class="container">
              <div class="row">
                <div
                  class="col d-flex justify-content-center mb-4"
                  *ngFor="let image of paymentSelected?.images"
                >
                  <img
                    height="50px"
                    class="rounded-3"
                    [src]="image"
                  />
                </div>
              </div>
            </div>
          </div>

          <h5 class="card-title text-center">Detalles de pago</h5>
          <div
            class="row d-flex justify-content-center"
            *ngIf="paymentSelected; else elseBlock"
          >
            <div class="table-responsive col-md-7">
              <table class="table table-hover">
                <thead>
                  <tr class="table-light">
                    <th>Item</th>
                    <th>Descripción</th>
                    <th>Monto</th>
                  </tr>
                </thead>
                <tbody class="table-group-divider">
                  <tr *ngFor="let field of receiptItemList; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ field.descripcion }}</td>
                    <td class="fw-bold">
                      {{ field.monto | currency : 'S/. ' }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="text-right">Comisión</td>
                    <td class="text-danger">{{ totalComision | currency : 'S/. ' }} *</td>
                  </tr>
                  <tr class="table-primary">
                    <td></td>
                    <td class="text-right">Total</td>
                    <td class="fw-bold">
                      {{ totalAmount | currency : 'S/. ' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="text-danger text-center">
              <small>
                (*) Los pagos en línea aplican una comisión al precio base de su servicio. Le garantizamos una
                transacción rápida y segura. Si está de acuerdo continua con el pago.
              </small>
            </p>
          </div>

          <ng-template #elseBlock>
            <div class="alert alert-danger text text-center">
              <strong>Seleccione un medio de pago para continuar</strong>
            </div>
          </ng-template>
          <section class="text-center">
            <span class="me-2">
              <a
                class="btn btn-primary"
                [routerLink]="['/recibos-y-pagos']"
                role="button"
              >
                REGRESAR
              </a>
            </span>
            <button
              (click)="payment()"
              class="text-white btn btn-md btn-primary ps-4 pe-4"
              [disabled]="!paymentSelected"
            >
              PAGAR
            </button>
          </section>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="responseStatus == 'error' && paymentStatus == null">
  <div class="card">
    <div class="card-body">
      <p class="text-center">No se puede procesar la transacción.</p>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="paymentStatus == 'success' && (paymentRegistrationStatus == 'success' || paymentRegistrationStatus == 'error')"
>
  <div class="card">
    <div class="card-body">
      <app-alert
        type="success"
        class="mt-pagos"
      >
        El pago se realizó exitosamente!
      </app-alert>
      <a
        class="btn btn-primary"
        [routerLink]="['/recibos-y-pagos']"
        role="button"
      >
        Ir a Mis Recibos
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="paymentStatus == 'error'">
  <div class="card">
    <div class="card-body">
      <app-alert
        type="error"
        class="mt-pagos"
      >
        No se puedo realizar el pago. {{ userErrorMessage }}
        <span class="ms-3">
          <a
            class="btn btn-primary"
            [routerLink]="['/recibos-y-pagos']"
            role="button"
          >
            Ir al Mis Recibos
          </a>
        </span>
      </app-alert>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="paymentStatus == 'loading'">
  <div>
    <app-ui-modal
      dialogClass="modal-dialog-centered"
      [containerClick]="false"
      [launch]="true"
      [hideHeader]="true"
    >
      <div class="app-modal-body">
        <p class="text-center fw-bold">Procesando pago...</p>
        <div class="container-spinner">
          <div class="item-1"></div>
          <div class="item-2"></div>
          <div class="item-3"></div>
          <div class="item-4"></div>
          <div class="item-5"></div>
        </div>
      </div>
    </app-ui-modal>
  </div>
</ng-container>

<swal
  #alertSwal
  title="Seleccione un método de pago"
  icon="warning"
></swal>
