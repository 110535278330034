import { MonoTypeOperatorFunction, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import type { ZodType } from 'zod';

export function parseResponse<T>(schema: ZodType): MonoTypeOperatorFunction<T> {
  return tap({
    next: (value: any) => {
      if (environment.production === false) {
        // Throw in development so we're aware of the error
        schema.parse(value);
      } else {
        // const parsed = schema.safeParse(value);
        // if (!parsed.success) {
        // Log to service to be informed
        // }
      }
    },
  });
}
