// import { HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs';
import { BaseApiEndpoint } from './base-endpoint';

export class ReceiptPayEndpoint extends BaseApiEndpoint {
  /* create(reciboClientePago: any): Observable<any> {
    let json = JSON.stringify(reciboClientePago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this.path + 'recibo-cliente-pago', params, {
      headers: headers,
    });
  } */
  /* update(reciboClientePago: any, id: number): Observable<any> {
    let json = JSON.stringify(reciboClientePago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.put(this.path + 'recibo-cliente-pago/' + id, params, {
      headers: headers,
    });
  } */
}
