import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { UserService } from '../../../services/user.service';
import { Company, UserDetails } from '../../../models/user';
import { ReciboCliente } from 'src/app/models/api-response';
import { MyPlan, UserIdentity } from 'src/app/models/api-response/user';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit {
  @ViewChild(NgbCarousel) carousel: NgbCarousel;

  @ViewChild('chart') chart: ChartComponent;

  chartOptions: ChartOptions = {
    tooltip: {
      enabled: true,
      x: {
        formatter(val, _) {
          let text = val + '';
          text = text.toLowerCase();
          return text.charAt(0).toUpperCase() + text.slice(1);
        },
      },
    },
    series: [
      {
        name: 'Monto total del recibo',
        data: [],
      },
    ],

    chart: {
      height: 250,
      type: 'bar',
      width: '100%',
      defaultLocale: 'es',
      locales: [
        {
          name: 'es',
          options: {
            toolbar: {
              selection: 'Seleccionar',
              selectionZoom: 'Seleccionar zoom',
              zoomIn: 'Acercar',
              zoomOut: 'Alejar',
              pan: 'Arrastre',
              reset: 'Reiniciar',
              exportToCSV: 'Exportar CSV',
              exportToSVG: 'Exportar SVG',
              exportToPNG: 'Exportar PNG',
            },
          },
        },
      ],
    },
    title: {
      text: 'Recibos',
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter(value, _, _1) {
          return value.split('-')[0];
        },
      },
    },
    yaxis: {
      title: {
        text: 'Soles',
      },
      labels: {
        formatter(val, _) {
          return 'S/ ' + val;
        },
      },
    },
  };

  totalDebt: number = 0;
  clientPlans: MyPlan[];

  identity: UserIdentity;
  recibo_cliente_list: ReciboClienteEnabled[] = []; // Array de los recibos del cliente

  isUgel: boolean = false;

  constructor(private _user: UserService, private _api: ApiService, private _authService: AuthService) {
    this.identity = _user.getIdentity();
    this.isUgel = this._authService.isUgel();
  }

  ngOnInit(): void {
    if (!this.isUgel) {
      this.getTotalDebt();
      this.getPlans();

      this._user.getHistoricChartData(this.identity.sub).subscribe({
        next: (data) => {
          this.chartOptions.series = [{ data: data.y }];
          this.chartOptions.xaxis.categories = data.x;
          this.chart.updateOptions(this.chartOptions);
        },
      });
    }
  }

  getTotalDebt(): void {
    this._api.customer.getTotalDebt(this.identity.sub).subscribe({
      next: ({ data }) => {
        this.totalDebt = data;
      },
    });
  }

  getPlans(): void {
    this._api.customer.getMyPlans(this.identity.sub).subscribe({
      next: (res) => {
        this.clientPlans = res.data;
      },
    });
  }

  getCompany(userDetail: UserDetails): Company {
    return {
      id: userDetail.id,
      ruc: userDetail.ruc || '',
      name: userDetail.name || '',
      businessName: userDetail.businessName || '',
      email: userDetail.email,
      cellPhone: userDetail.cellPhone,
      cellPhone2: userDetail.cellPhone2,
      phone: userDetail.phone,
    };
  }
}

interface ReciboClienteEnabled extends ReciboCliente {
  enabled?: boolean;
}

export type ChartOptions = {
  tooltip: ApexTooltip;
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
};
